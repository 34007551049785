<template>
  <div class="mb-4">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between">
          <div>
            <div class="form-inline">
              <template v-if="displayTextFilter">
                <div class="form-group mt-1 mobile-wid-100">
                  <input type="text"
                        class="form-control quickfilter-input mobile-wid-100"
                        :placeholder="placeholderTextFilter"
                        @change="changePage(1)"
                        v-model="textFilter">
                </div>
                <div class="ml-2 hidden-md"></div>
              </template>

              <template v-if="displayTypeFilter">
                <div class="form-group mt-1 mobile-wid-100">
                  <select class="custom-select mobile-wid-100" @change="changePage(1)" v-model="typeFilter">
                    <option value="">Type</option>
                    <option v-for="item in listTypeFilter" :key="item.value" :value="item.value">{{ item.label }}</option>
                  </select>
                </div>
                <div class="ml-2 hidden-md"></div>
              </template>

              <div v-if="displayStatusFilter" class="form-group mt-1 mobile-wid-100">
                <select class="custom-select mobile-wid-100" @change="changePage(1)" v-model="statusFilter">
                  <option value="">Statut</option>
                  <option v-for="item in listStatusFilter" :key="item.value" :value="item.value">{{ item.label }}</option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <div class="form-inline">
              <template v-if="displaySyncData">
                <div class="ml-2 hidden-md"></div>
                <div class="form-group mt-1">
                  <a class="btn btn-sm btn-link" @click.prevent="syncData" >
                    <fa-icon class="fa-2x text-info" :icon="['fas', 'sync']"></fa-icon>
                  </a>
                </div>
              </template>

              <template v-if="displayReset && hasDefinedFilters">
                <div class="ml-2 hidden-md"></div>
                <div class="form-group mt-1 mobile-wid-100">
                  <a class="btn btn-sm btn-link" @click.prevent="reset" >
                    <span class="fa-stack">
                      <fa-icon class="fa-stack-2x text-secondary" :icon="['fas', 'filter']"></fa-icon>
                      <fa-icon class="fa-stack fa-custom-times text-danger" :icon="['fas', 'times']"></fa-icon>
                    </span>
                  </a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pagination from '@/mixins/pagination'

export default {
  mixins: [pagination],
  props: {
    state: {
      type: String,
      required: true
    },
    displayTextFilter: {
      type: Boolean,
      default: false
    },
    placeholderTextFilter: {
      Type: String,
      default: ''
    },
    displayStatusFilter: {
      type: Boolean,
      default: false
    },
    listStatusFilter: {
      type: Array,
      default: () => []
    },
    displayTypeFilter: {
      type: Boolean,
      default: false
    },
    listTypeFilter: {
      type: Array,
      default: () => []
    },
    displayReset: {
      type: Boolean,
      default: false
    },
    displaySyncData: {
      type: Boolean,
      default: false
    },
    hasSortFilters: {
      type: Boolean,
      default: false
    },
    toastSyncData: {
      Type: String,
      default: 'Les produits sont actualisées'
    }
  },
  computed: {
    hasDefinedFilters () {
      return this.textFilter.trim().length > 0 || this.typeFilter || this.statusFilter || this.hasSortFilters
    }
  },
  methods: {
    reset () {
      if (this.displayTextFilter) {
        this.textFilter = ''
      }

      if (this.displayStatusFilter) {
        this.statusFilter = ''
      }

      if (this.displayTypeFilter) {
        this.typeFilter = ''
      }

      this.sortBy = ''
      this.sortDirection = 'DESC'
      this.changePage(1)
      this.$toasted.info('Les filtres sont réinitialisés')
    },
    async syncData () {
      this.changePage(this.page)
      this.$toasted.info(this.toastSyncData)
    },
    changePage (page) {
      this.$emit('changePage', page)
    }
  },
  created () {
    this.instance = this.state
  }
}
</script>

<style lang="scss">
.fa-custom-times {
  font-size: 1rem;
  position: absolute;
  top: 6px;
  left: 23px;
}
</style>

<template>
  <tr :key="item.id">
    <td>
      <router-link target="_blank" :to="`/products?variantId=${item.orderItem.variantId}`">
        {{ productName }}
        <template v-if="sku"><br><small>SKU: {{ sku }}</small></template>
      </router-link>
      <br><strong>Motif:</strong> {{ item.reasonText }}
    </td>
    <td class="text-center">{{ quantity }}</td>
    <td>{{ status }}</td>
  </tr>
</template>

<script>
import { get } from 'lodash-es'
import formatsCurrency from '@/mixins/formatsCurrency'
import returnStatus from '@/mixins/returnStatus'

export default {
  mixins: [formatsCurrency, returnStatus],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    sku () {
      return get(this.item, 'orderItem.variant.sku', '')
    },
    productName () {
      return get(this.item, 'orderItem.productName')
    },
    quantity () {
      return get(this.item, 'quantity', 0)
    },
    status () {
      return this.getReturnRequestItemStatusText(this.item.status)
    },
    areActionsAvailable () {
      return (
        this.item.status !== 'completed' &&
        this.item.status !== 'returnExpired' &&
        this.item.status !== 'pendingReturn' &&
        this.item.status !== 'returnDenied'

      )
    },
    isPendingReturn () {
      return this.item.status === 'pendingReturn'
    }
  }
}
</script>

<template>
  <div @click.prevent="onClick(storeId)">
    {{ name }}
    <fa-icon class="fa-1x ml-2" :icon="['fas', 'search']"></fa-icon>
  </div>
</template>

<script>

export default {
  props: {
    storeId: {
      type: String,
      default () {
        return ''
      }
    },
    name: {
      type: String,
      default () {
        return ''
      }
    },
    onClick: {
      type: Function,
      default: () => null
    }
  }
}
</script>

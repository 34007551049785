<template>
  <app-layout>
    <div class="px-4 p-sm-5 container-fluid">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Retours</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <pagination-filter
          state="returnRequests"
          placeholder-text-filter="Chercher un retour"
          :display-text-filter="true"
          :display-status-filter="true"
          :list-status-filter="getStatusList"
          :display-reset="true"
          :display-sync-data="true"
          toast-sync-data="Les retours sont actualisées"
          :has-sort-filters="this.sortBy !== ''"
          @changePage="changePage"
        />

        <div class="card mb-4">
          <div class="card-body p-0">
            <collection-table
              :cols="cols"
              :list-items="returnRequests"
              :show-actions="false"
              :sort="sortBy"
              :sort-direction="sortDirection"
              :only-display-sort-col="true"
              :is-fetching="isFetching"
              no-top-border
              hover
              :link="(item) => `/return-requests/${item.id}/store/${item.storeId}`"
              empty-message="Vous n'avez aucun retour"
              @sortBy="handleSortBy"
            ></collection-table>

            <div v-if="paginatedData.data.length > 0" class="drawer-footer d-flex justify-content-center align-items-center">
              <pagination state="returnRequests"
                          :current-page="page"
                          :pages="paginatedData.pageCount"
                          :range="2"
                          :display-per-page="true"
                          @change="changePage" />
            </div>
          </div>
        </div>
      </template>

    </div>
  </app-layout>
</template>

<script>
import formatsDate from '@/mixins/formatsDate'
import returnStatus from '@/mixins/returnStatus'
import Pagination from '@/components/common/Pagination'
import { get } from 'lodash-es'
import PaginationFilter from '@/components/common/PaginationFilter'
import pagination from '@/mixins/pagination'
import StoreLabelFilter from '@/components/stores/LabelFilter'

export default {
  components: {
    Pagination,
    PaginationFilter
  },
  mixins: [formatsDate, returnStatus, pagination],
  computed: {
    getStatusList () {
      return [
        {
          value: 'accepted',
          label: 'Acceptée'
        },
        {
          value: 'pending',
          label: 'Effectuée'
        },
        {
          value: 'closed',
          label: 'Finalisée'
        }
      ]
    },
    cols () {
      return [
        {
          header: 'Date',
          property: 'createdAt',
          headerClass: 'col-date',
          method: (item) => {
            return this.$longDateTime(item.createdAt)
          },
          isSortable: true
        },
        {
          header: 'Numéro de la demande',
          property: 'reference',
          isSortable: true
        },
        {
          header: 'Commande',
          property: 'order.reference',
          method: (item) => {
            return get(item, 'order.reference')
          },
          isSortable: true
        },
        {
          header: 'Boutique',
          property: 'store.name',
          component: StoreLabelFilter,
          props: (item) => {
            return {
              name: item.store.name,
              storeId: item.store.id,
              onClick: (storeId) => {
                this.textFilter = storeId
                this.changePage(1)
              }
            }
          },
          isSortable: true
        },
        {
          header: 'Client',
          property: 'customer.firstName',
          method: (item) => {
            if (!item.customer) {
              return ''
            }

            const firstName = get(item.customer, 'firstName', '')
            const lastName = get(item.customer, 'lastName', '')

            return `${firstName} ${lastName}`.trim()
          },
          isSortable: true
        },
        {
          header: 'Articles',
          property: 'totalQuantity',
          method: (item) => {
            return get(item.returnRequestItems, 'length', 0)
          }
        },
        {
          header: 'Statut',
          property: 'status',
          method: (item) => {
            return this.getReturnRequestStatusText(item.status)
          },
          isSortable: true
        }
      ]
    },
    returnRequests () {
      return this.paginatedData.data
    }
  },
  methods: {
    async changePage (page) {
      await this.fetchReturnRequests(page)
    },
    async fetchReturnRequests (page) {
      await this.$store.dispatch(`${this.instance}/fetch`, { page })
    },
    async handleSortBy (evt) {
      this.sortBy = evt.property
      this.sortDirection = evt.direction

      await this.fetchReturnRequests()
    }
  },
  async created () {
    this.instance = 'returnRequests'
    await this.fetchReturnRequests()
  }
}
</script>
